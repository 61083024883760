import { DocumentType, InquiryType } from '@innedit/innedit-type';
import { InquiryData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ListItemProps } from '~/containers/Espace/List/props';

import ListItem from './index';

const ListItemInquiry: FC<ListItemProps<InquiryType, InquiryData>> = ({
  className,
  docId,
  index,
  onClick,
  model,
}) => {
  const { t } = useTranslation();
  const [doc, setDoc] = useState<DocumentType<InquiryType>>();

  useEffect(() => {
    let isMounted = true;
    const unsub = model.watchById(docId, document => {
      if (isMounted) {
        setDoc(document);
      }
    });

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  return (
    <ListItem
      className={className}
      date={doc.createdAt}
      description={t('inquiry.item.nb-messages', {
        count: doc.messages.length,
      })}
      displayActionId={false}
      doc={doc}
      index={index}
      label={doc.contactName}
      onClick={onClick}
    />
  );
};

export default ListItemInquiry;
