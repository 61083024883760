import { ModelType } from '@innedit/innedit-type';
import mergeWith from 'lodash/mergeWith';
import objectHash from 'object-hash';
import { ModelData, UserData } from 'packages/innedit';
import React, { ReactElement, SyntheticEvent } from 'react';

import { MenuType } from '~/components/Actions';
import Button, { ButtonProps } from '~/components/Button';
import ViewList from '~/components/View/List';
import { ListProps } from '~/containers/Espace/List/props';
import IconList from '~/icons/List';
import IconPlus from '~/icons/Plus';
import IconTh from '~/icons/Th';

import ListBody from './Body';

const List = <T extends ModelType, M extends ModelData<T>>(
  props: ListProps<T, M>,
): ReactElement | null => {
  const {
    displayDelete,
    displayHeader,
    filters = [],
    footerActions,
    itemGrid,
    itemPathnamePrefix,
    menu = { left: [], right: [] },
    model,
    removeAddButton,
    search,
    subMenu = [],
    tabIndex = 0,
    title,
    user,
  } = props;
  const defaultMenu: Required<MenuType> = {
    left: [],
    right: [],
  };

  if (displayDelete) {
    // defaultMenu.left.push({
    //   color: 'danger',
    //   iconLeft: IconDelete,
    //   onClick: handleDeleteOnClick,
    //   variant: 'outline',
    // });
  }

  const customizer = (
    objValue: ButtonProps[],
    srcValue: ButtonProps[],
  ): ButtonProps[] => [...srcValue, ...objValue];

  const handleResetOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    await model.resetIndex();
  };

  const handleListOnClick = async () => {
    const userData = new UserData();
    await userData.set(user.id, {
      defaultListItemMode: 'list',
    });
  };

  const handleGridOnClick = async () => {
    const userData = new UserData();
    await userData.set(user.id, {
      defaultListItemMode: 'grid',
    });
  };

  if (!removeAddButton) {
    defaultMenu.right.push({
      icon: IconPlus,
      label: model.addButtonLabel,
      to: `${itemPathnamePrefix}create`,
    });
  }

  if (itemGrid) {
    defaultMenu.right.push([
      {
        disabled: 'list' === user.defaultListItemMode,
        icon: IconList,
        label: 'Afficher sous forme de liste',
        onClick: handleListOnClick,
        selected: 'list' === user.defaultListItemMode,
      },
      {
        disabled: 'grid' === user.defaultListItemMode,
        icon: IconTh,
        label: 'Afficher sous forme de grille',
        onClick: handleGridOnClick,
        selected: 'grid' === user.defaultListItemMode,
      },
    ]);
  }

  return (
    <>
      <ViewList
        displayHeader={displayHeader}
        displaySearch={model.canDoSearch}
        filters={filters}
        menu={mergeWith(defaultMenu, menu, customizer)}
        search={search}
        subMenu={subMenu}
        tabIndex={tabIndex}
        tabs={model.tabs}
        title={title}
      >
        <ListBody {...props} />
      </ViewList>
      {(footerActions || model.canDoSearch) && (
        <div className="flex items-center justify-start -mt-9">
          {footerActions &&
            (!Array.isArray(footerActions)
              ? [footerActions]
              : footerActions
            ).map(({ tooltip, text, type, ...others }) => (
              <Button
                key={`actions_${objectHash({
                  others,
                  text,
                  tooltip,
                  type,
                })}`}
                color="neutral"
                size="sm"
                variant="ghost"
                {...others}
              />
            ))}
          <Button
            color="neutral"
            onClick={handleResetOnClick}
            size="sm"
            text="Synchroniser l'index de recherche"
            variant="ghost"
          />
        </div>
      )}
    </>
  );
};

export default List;
